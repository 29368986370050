@import '../../../../bb_base/scss/theme-bootstrap';

.lc-service-drawer {
  &-v1 {
    #{$rdirection}: 0;
    background: $color-white;
    bottom: 0;
    box-shadow: 0 0 4px $color-grey-light;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 99999;
    @include breakpoint($large-up) {
      #{$rdirection}: 5px;
      bottom: calc(60px + 4em);
      width: 400px;
    }
  }
  h3.lc-service-drawer__header-text {
    color: $color-white;
    font-family: $bbtext-regular;
  }
  &__header-container {
    background: $color-black;
    font-family: $bbtext-regular;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: start;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-white;
    font-family: $bb-sans;
    font-size: 18px;
    margin: 0;
    text-transform: inherit;
    flex: 1 50%;
    text-align: left;
    @include breakpoint($large-up) {
      font-size: 22px;
    }
  }
  &__body-container {
    padding: 15px;
    color: $color-black;
    font-family: $bbtext-regular;
    .lc-service-drawer {
      &__button {
        background: $color-black;
        color: $color-white;
        cursor: pointer;
        display: block;
        font-size: 14px;
        font-family: $bbtext-bold;
        margin: 10px 0;
        padding: 15px 10px;
        text-align: center;
        width: 100%;
        &:hover {
          background: $color-cta-hover;
          color: $color-white;
        }
      }
    }
  }
  &__logo {
    fill: $color-white;
    height: 2em;
    width: 10em;
    justify-content: right;
    align-items: self-end;
    float: right;
    flex: 1 50%;
    @include breakpoint($large-up) {
      width: 8em;
    }
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @media #{$small-down} {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
}

.rounded-live-chat-button {
  font-family: $bbtext-regular;
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-black;
  border-radius: 2em;
  bottom: 60px;
  color: $color-white;
  cursor: pointer;
  display: none;
  justify-content: center;
  padding: 1em;
  position: fixed;
  z-index: 1000;
  &__down-caret {
    border-#{$rdirection}: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
  }
  &:active {
    background: $color-cta-hover;
  }
  &:hover {
    background: $color-cta-hover;
    color: $color-white;
    .rounded-live-chat-button {
      &__down-caret {
        border-#{$rdirection}: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
      }
    }
  }
}

.LPMcontainer {
  visibility: hidden;
}

.device-mobile {
  .rounded-live-chat-button {
    bottom: 80px;
  }
}

.rich-menu-items {
  button {
    height: auto;
    letter-spacing: revert;
    text-transform: none;
    white-space: revert;
  }
}
